import React from 'react'
import { StoresContext, stores } from 'spartacus/stores'

const useStores = (): typeof stores => {
  const context = React.useContext(StoresContext)

  if (!context) {
    throw new Error('useStores must be used within a StoreProvider.')
  }

  return context
}

export default useStores
